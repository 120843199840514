import React, { FC, useEffect, memo } from 'react'
import { SingleWatcher } from 'kat/lib/com/watcher/SingleWatcher'
import { DocumentSnapshotClient } from 'kat/lib/sdks/firebase/types/DocumentSnapshotClient'
import { getFirestore } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { WatcherTypesBase } from 'kat/lib/types/WatcherTypesBase'
import { SetCurrentGameStatAction } from 'state/actions/SetCurrentGameStatAction'
import { GameStatModel } from 'functions/types/GameStatModel'
import { getGameStatRefClient } from 'functions/utilities/ref/gameStat/getGameStatRefClient'

interface GameStatWatcherProps {
  gameId: string
}

const description = 'GameStatWatcher'

export const GameStatWatcher: FC<GameStatWatcherProps> = memo(({ gameId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<SetCurrentGameStatAction>({
      type: 'SET_CURRENT_GAME_STAT_ACTION',
      description,
      gameStat: null,
    })
  }, [dispatch])

  const workspaceId = useSelector((state: StateReducers) => {
    return state.currentWorkspaceId
  })

  if (!workspaceId) {
    return null
  }

  return (
    <SingleWatcher
      onNext={(docSnapshot: DocumentSnapshotClient<GameStatModel>) => {
        if (workspaceId) {
          const gameStat = docSnapshot.data()
          if (gameStat) {
            dispatch<SetCurrentGameStatAction>({
              type: 'SET_CURRENT_GAME_STAT_ACTION',
              description,
              gameStat,
            })
          } else {
            dispatch<SetCurrentGameStatAction>({
              type: 'SET_CURRENT_GAME_STAT_ACTION',
              description,
              gameStat: null,
            })
          }
        }
      }}
      getRef={() => {
        return getGameStatRefClient({
          firestore: getFirestore(),
          workspaceId,
          gameId,
        })
      }}
      watcherKey={description as WatcherTypesBase}
    />
  )
})
