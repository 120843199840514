import React, { FC, useEffect, memo } from 'react'
import { SingleWatcher } from 'kat/lib/com/watcher/SingleWatcher'
import { DocumentSnapshotClient } from 'kat/lib/sdks/firebase/types/DocumentSnapshotClient'
import { getFirestore } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { SharedGameModel } from 'functions/types/SharedGameModel'
import { WatcherTypesBase } from 'kat/lib/types/WatcherTypesBase'
import { SetCurrentSharedGameAction } from 'state/actions/SetCurrentSharedGameAction'
import { getSharedGameRefClient } from 'functions/utilities/ref/sharedGame/getSharedGameRefClient'

interface SharedGameWatcherProps {
  gameId: string
}

const description = 'SharedGameWatcher'

export const SharedGameWatcher: FC<SharedGameWatcherProps> = memo(
  ({ gameId }) => {
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch<SetCurrentSharedGameAction>({
        type: 'SET_CURRENT_SHARED_GAME_ACTION',
        description,
        values: null,
      })
    }, [dispatch])

    const workspaceId = useSelector((state: StateReducers) => {
      return state.currentWorkspaceId
    })

    if (!workspaceId) {
      return null
    }

    return (
      <SingleWatcher
        onNext={(docSnapshot: DocumentSnapshotClient<SharedGameModel>) => {
          if (workspaceId) {
            const sharedGame = docSnapshot.data()
            if (sharedGame) {
              dispatch<SetCurrentSharedGameAction>({
                type: 'SET_CURRENT_SHARED_GAME_ACTION',
                description,
                values: {
                  workspaceId,
                  gameId,
                  sharedGame,
                },
              })
            } else {
              dispatch<SetCurrentSharedGameAction>({
                type: 'SET_CURRENT_SHARED_GAME_ACTION',
                description,
                values: {
                  workspaceId,
                  gameId,
                  sharedGame: null,
                  isNotAvailable: true,
                },
              })
            }
          }
        }}
        getRef={() => {
          return getSharedGameRefClient({
            firestore: getFirestore(),
            workspaceId,
            gameId,
          })
        }}
        watcherKey={description as WatcherTypesBase}
      />
    )
  }
)
