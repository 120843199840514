import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StateReducers } from '../../state/reducers/types/StateReducers'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { appRoutes } from 'functions/routes/appRoutes'
import { createLocationAction } from 'state/actions/createLocationAction'

const description = 'LoginRedirectWatcher'

interface Props {}

export const LoginRedirectWatcher: FC<Props> = () => {
  const dispatch = useDispatch()

  const signedIntoDashboard = useSelector((state: StateReducers) => {
    return state.cookieStorage?.signedIntoDashboard
  })

  const isCurrentLocationSet = useSelector((state: StateReducers) => {
    return !!state.currentLocation
  })

  const isTargetedRoute = useSelector((state: StateReducers) => {
    const loginRoute = state.currentLocation?.isRoutes?.loginRoute
    const emailLoginRoute = state.currentLocation?.isRoutes?.emailLoginRoute
    const createUserRoute = state.currentLocation?.isRoutes?.createUserRoute
    return loginRoute || createUserRoute || emailLoginRoute
  })

  const isPrelogin = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.isPreLoginRoute
  })

  const useQuery = useSelector((state: StateReducers) => {
    const currentQuery = state.currentLocation?.query
    const acceptInvite =
      currentQuery?.inviteId &&
      currentQuery?.inviteWorkspaceId &&
      currentQuery?.secret

    return acceptInvite ? currentQuery : null
  })

  useEffect(() => {
    if (signedIntoDashboard) {
      if (isPrelogin) {
        const usePathname = useQuery
          ? appRoutes.acceptInviteRoute.pathname
          : appRoutes.postLoginHomeRoute.pathname

        dispatch<HistoryAction>(
          createLocationAction({
            description,
            method: 'replace',
            pathname: usePathname,
            query: useQuery || {},
          })
        )
      }
    } else if (isCurrentLocationSet && !isPrelogin) {
      dispatch<HistoryAction>(
        createLocationAction({
          description,
          method: 'replace',
          pathname: appRoutes.loginRoute.pathname,
        })
      )

      setTimeout(() => {
        // helps clear out some memory
        window.location.reload()
      }, 300)
    }
  }, [
    isTargetedRoute,
    useQuery,
    signedIntoDashboard,
    dispatch,
    isPrelogin,
    isCurrentLocationSet,
  ])

  return null
}
