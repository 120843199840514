import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getBackLocation } from 'state/getters/getBackLocation'
import { StateReducers } from 'state/reducers/types/StateReducers'

export const CanGoBackWatcher: FC = () => {
  const hasBackRoute = useSelector((state: StateReducers) => {
    const { route } = getBackLocation({
      currentLocation: state.currentLocation,
    })
    return !!route
  })

  const isNative = useSelector((state: StateReducers) => {
    return (
      state.windowMeta?.platformType === 'AndroidNative' ||
      state.windowMeta?.platformType === 'IosNative'
    )
  })

  useEffect(() => {
    if (isNative && window.setCanGoBack) {
      window.setCanGoBack({
        canGoBack: hasBackRoute,
      })
    }
  }, [hasBackRoute, isNative])

  return null
}
