import { currentSharedGameReducer } from './currentSharedGameReducer'
import { AllActions } from './../actions/AllActions'
import { combineReducers, CombinedState } from 'redux'
import { StateReducers } from './types/StateReducers'
import { combinedReducersBase } from 'kat/lib/state/combinedReducersBase'
import { sharedGamesReducer } from './sharedGamesReducer'
import { currentGameStatReducer } from './currentGameStatReducer'
import { currentGameReducer } from './currentGameReducer'
import { currentCardReducer } from './currentCardReducer'
import { cardsReducer } from './cardsReducer'
import { cardsQueryReducer } from './cardsQueryReducer'
import { previewCardReducer } from './previewCardReducer'

const appReducer = combineReducers<StateReducers>({
  ...combinedReducersBase,
  sharedGames: sharedGamesReducer,
  cards: cardsReducer,
  currentGame: currentGameReducer,
  // currentGameId: currentGameIdReducer, decided not to add because we can use location query
  currentCard: currentCardReducer,
  currentGameStat: currentGameStatReducer,
  currentSharedGame: currentSharedGameReducer,
  cardsQuery: cardsQueryReducer,
  previewCard: previewCardReducer,
})

export const initialState = appReducer({}, { type: 'INITIAL' }) as StateReducers

export const rootReducer = (
  state: StateReducers,
  action: AllActions
): CombinedState<StateReducers> => {
  if (action.type === 'SIGN_OUT_SUCCESS') {
    // const sessionStorage = state.sessionStorage || undefined
    // const cookieStorage = state.cookieStorage || undefined
    // const isEndToEndTesting = sessionStorage && sessionStorage.isEndToEndTesting
    const windowMeta = state.windowMeta || undefined
    const currentLocation = state.currentLocation || undefined

    const newState: StateReducers = {
      ...initialState,
      // sessionStorage: {
      //   ...initialState.sessionStorage,
      //   isEndToEndTesting,
      // },
      windowMeta,
      currentLocation,
      // cookieStorage,
    }

    return appReducer(newState, action)
  }

  return appReducer(state, action)
}
