import { createId } from 'kat/lib/utilities/create/createId'
import { CardSquare } from '../types/CardSquare'

interface Props {
  prompts: string[]
  cardSize: number
}

export const createCardSquares = ({
  prompts,
  cardSize,
}: Props): CardSquare[] => {
  const cardSquaresMutable: CardSquare[] = []

  let promptIndexes: number[] = prompts.map((_prompt, index) => {
    return index
  })

  while (cardSquaresMutable.length < cardSize && promptIndexes?.length) {
    const randomIndex = Math.floor(Math.random() * promptIndexes.length)

    cardSquaresMutable.push({
      id: createId(),
      promptIndex: promptIndexes[randomIndex],
    })

    promptIndexes = [
      ...promptIndexes.slice(0, randomIndex),
      ...promptIndexes.slice(randomIndex + 1),
    ]
  }

  return cardSquaresMutable
}
