import { SetCurrentSharedGameAction } from '../actions/SetCurrentSharedGameAction'
import { CurrentSharedGameReducer } from './types/CurrentSharedGameReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'

export const currentSharedGameReducer = (
  state?: CurrentSharedGameReducer | null,
  action?: SetCurrentSharedGameAction | ClearWorkspaceScopeAction
): CurrentSharedGameReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CURRENT_SHARED_GAME_ACTION': {
      const { sharedGame, workspaceId, gameId, isNotAvailable } =
        action.values || {}

      if (workspaceId && gameId) {
        return {
          ...state,
          workspaceId,
          data:
            sharedGame === null
              ? null
              : {
                  ...state?.data,
                  ...sharedGame,
                },
          gameId,
          isNotAvailable,
        }
      }

      return null
    }

    case 'CLEAR_WORKSPACE_SCOPE': {
      return null
    }

    default:
      return state || null
  }
}
