import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { SharedGameModel } from '../../../types/SharedGameModel'
import { CollectionReferenceClient } from 'kat/lib/sdks/firebase/types/CollectionReferenceClient'
import { collection } from 'firebase/firestore'
import { getWorkspaceRefClient } from 'kat/lib/functions/utilities/ref/get/getWorkspaceRefClient'
import { collectionNames } from '../../../collection/collectionNames'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
}

export const getSharedGamesRefClient = ({
  firestore,
  workspaceId,
}: Props): CollectionReferenceClient<SharedGameModel> => {
  const workspaceRef = getWorkspaceRefClient({ firestore, workspaceId })
  const ref = collection(workspaceRef, collectionNames.sharedGames)
  return ref
}
