import { PreviewCardReducer } from './types/PreviewCardReducer'
import { SetCurrentSharedGameAction } from 'state/actions/SetCurrentSharedGameAction'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { createCardSquares } from 'functions/playerCustomClaim/createCardSquares'

export const previewCardReducer = (
  state?: PreviewCardReducer | null,
  action?: SetCurrentSharedGameAction | ClearWorkspaceScopeAction
): PreviewCardReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CURRENT_SHARED_GAME_ACTION': {
      const { sharedGame } = action.values || {}

      if (sharedGame && sharedGame?.cardSize) {
        const cardSquares = createCardSquares({
          cardSize: sharedGame?.cardSize,
          prompts: sharedGame.prompts || [],
        })
        return {
          ...state,
          cardSquares: cardSquares,
          uploadedCount: 0,
          public: {
            publicPlayerName: `Player's name`,
            publicEmoji: '🐢',
          },
        }
      }

      return null
    }

    case 'CLEAR_WORKSPACE_SCOPE': {
      return null
    }

    default:
      return state || null
  }
}
