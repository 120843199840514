import { queryNames } from 'functions/routes/queryNames'
import { HistoryMethod } from 'kat/lib/types/HistoryMethod'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { createLocation } from 'kat/lib/routes/createLocation'
import { UrlQuery } from 'functions/routes/types/UrlQuery'

interface Props {
  description: string
  method: HistoryMethod
  pathname: string
  query?: UrlQuery
}

export const createLocationAction = ({
  description,
  method,
  pathname,
  query,
}: Props): HistoryAction => {
  return {
    type: 'CALL_HISTORY_METHOD',
    description,
    values: {
      method,
      location: createLocation<UrlQuery>({
        pathname,
        query,
        queryNames,
      }),
    },
  }
}
