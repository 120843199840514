export const queryNamesBaseDefault = {
  workspaceId: 'workspace_id',
  subscriptionId: 'subscription_id',
  tinyUrlId: 'tiny_url_id', // also used in index.html
  userId: 'user_id',
  inviteId: 'invite_id',
  workspaceName: 'workspace_name',
  refreshIdToken: 'refresh_id_token',
  email: 'email',
  secret: 'secret',
  endToEndTesting: 'end-to-end-testing',
  modalType: 'modal_type',
  modalId: 'modal_id',
  inviteWorkspaceId: 'inviteWorkspaceId',
}

export const queryNames = {
  ...queryNamesBaseDefault,
  gameId: 'gameId',
  cardId: 'cardId',
  whichMain: 'whichMain',
  searchText: 'searchText',
  gi: 'gi', // gameId
  gp: 'gp', // guest password
  wi: 'wi', // workspace id
}
