import React, { FC, Suspense, lazy } from 'react'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { useSelector } from 'react-redux'
import { MainWatcher } from 'com/watcher/MainWatcher'
import { RootLoaderMain } from 'com/main/RootLoaderMain'
const AppMain = lazy(() => import('com/main/AppMain'))
const MarketingLayout = lazy(() => import('com/layout/MarketingLayout'))

interface IndexRouterProps {}

export const IndexRouter: FC<IndexRouterProps> = () => {
  const rootRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.rootRoute
  })

  // need a div otherwise will have rendering issues with react snap, unsure why
  return (
    <div>
      <RootLoaderMain />
      <MainWatcher />

      {rootRoute ? (
        <Suspense fallback={<></>}>
          <MarketingLayout />
        </Suspense>
      ) : (
        <Suspense fallback={<></>}>
          <AppMain />
        </Suspense>
      )}
    </div>
  )
}
