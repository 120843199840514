import { SetCardsQueryAction } from 'state/actions/SetCardsQueryAction'
import { CardsQueryReducer } from './types/CardsQueryReducer'

export const cardsQueryReducer = (
  state?: CardsQueryReducer | null,
  action?: SetCardsQueryAction
): CardsQueryReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CARDS_QUERY_ACTION': {
      const cardsQuery = action.cardsQuery

      return cardsQuery
    }

    default:
      return state || null
  }
}
