import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StateReducers } from '../../state/reducers/types/StateReducers'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { appRoutes } from 'functions/routes/appRoutes'
import { dispatchGetWorkspaces } from 'kat/lib/state/dispatch/dispatchGetWorkspaces'
import { getObjectFirstItem } from 'kat/lib/utilities/get/getObjectFirstItem'
import { createLocationAction } from 'state/actions/createLocationAction'

const description = 'GetWorkspacesWatcher'

interface Props {}

export const GetWorkspacesWatcher: FC<Props> = () => {
  const dispatch = useDispatch()

  const signedIntoDashboard = useSelector((state: StateReducers) => {
    return state.cookieStorage?.signedIntoDashboard
  })

  const currentUserId = useSelector((state: StateReducers) => {
    return state.currentUserId
  })

  const playGameRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.playGameRoute
  })

  const tinyRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.tinyRoute
  })

  const highlightsRoute = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes?.highlightsRoute
  })

  const currentWorkspaceId = useSelector((state: StateReducers) => {
    return state.currentWorkspaceId
  })

  const workspaceLoaded = useSelector((state: StateReducers) => {
    return state.workspaces !== null
  })

  const previousWorkspaceId = useSelector((state: StateReducers) => {
    return state.cookieStorage?.previousWorkspaceId
  })

  useEffect(() => {
    if (
      signedIntoDashboard &&
      !currentWorkspaceId &&
      !workspaceLoaded &&
      currentUserId &&
      !playGameRoute &&
      !tinyRoute &&
      !highlightsRoute
    ) {
      dispatchGetWorkspaces({
        dispatch,
        description,
        userId: currentUserId,
        onSucessRedirect: (workspaces) => {
          const { key } = getObjectFirstItem(workspaces)
          const workspaceIds = Object.keys(workspaces)
          const onlyWorkspaceId = workspaceIds.length === 1 ? key : undefined

          const useWorkspaceId: undefined | string =
            previousWorkspaceId && workspaceIds.includes(previousWorkspaceId)
              ? previousWorkspaceId
              : onlyWorkspaceId

          const workspaceRoute = appRoutes.workspaceHomeRoute.pathname

          const usePathname = useWorkspaceId
            ? workspaceRoute
            : appRoutes.selectWorkspaceRoute.pathname

          const useQuery = useWorkspaceId
            ? {
                workspaceId: useWorkspaceId,
              }
            : undefined

          dispatch<HistoryAction>(
            createLocationAction({
              description,
              method: 'replace',
              query: useQuery,
              pathname: usePathname,
            })
          )
        },
      })
    }
  }, [
    currentWorkspaceId,
    previousWorkspaceId,
    signedIntoDashboard,
    currentUserId,
    dispatch,
    workspaceLoaded,
    playGameRoute,
    tinyRoute,
    highlightsRoute,
  ])

  return null
}
