import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { appRoutes } from 'functions/routes/appRoutes'
import { createLocationChangeActionBase } from 'kat/lib/actionCreators/createLocationChangeActionBase'
import { UrlQuery } from 'functions/routes/types/UrlQuery'
import { queryNames } from 'functions/routes/queryNames'
import { HistoryAction } from 'kat/lib/actionCreators/types/HistoryAction'
import { ServiceWorkerMessageWatcher } from 'kat/lib/com/watcher/ServiceWorkerMessageWatcher'
import { createLocationAction } from 'state/actions/createLocationAction'

interface Props {}

const description = 'ServiceWorkerMessageWatcherWrapper'

export const ServiceWorkerMessageWatcherWrapper: FC<Props> = () => {
  const dispatch = useDispatch()

  return (
    <ServiceWorkerMessageWatcher
      redirect={(url: string) => {
        const { pathname, search, hash } = new URL(url)

        const locationChangeActionBase =
          createLocationChangeActionBase<UrlQuery>({
            description,
            search,
            hash,
            pathname,
            queryNames,
            routes: appRoutes,
          })

        dispatch<HistoryAction>(
          createLocationAction({
            description,
            method: 'push',
            query: locationChangeActionBase.values.query,
            pathname,
          })
        )
      }}
    />
  )
}
