import { GameStatModel } from './../../../types/GameStatModel'
import { CollectionReferenceClient } from 'kat/lib/sdks/firebase/types/CollectionReferenceClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { collection } from 'firebase/firestore'
import { getWorkspaceStatRefClient } from 'kat/lib/functions/utilities/ref/get/getWorkspaceStatRefClient'
import { collectionNames } from '../../../collection/collectionNames'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
}

export const getGameStatsRefClient = ({
  firestore,
  workspaceId,
}: Props): CollectionReferenceClient<GameStatModel> => {
  const workspaceStatRef = getWorkspaceStatRefClient({
    firestore,
    workspaceId,
  })
  const ref = collection(workspaceStatRef, collectionNames.gameStats)

  return ref
}
