import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { SharedGameModel } from '../../functions/types/SharedGameModel'
import { PostSharedGamesRequestAction } from '../actions/sharedGames/PostSharedGamesRequestAction'
import { PatchSharedGamesRequestAction } from '../actions/sharedGames/PatchSharedGamesRequestAction'
import { DeleteSharedGamesRequestAction } from '../actions/sharedGames/DeleteSharedGamesRequestAction'
import { DeleteSharedGamesSuccessAction } from '../actions/sharedGames/DeleteSharedGamesSuccessAction'
import { GetSharedGamesSuccessAction } from '../actions/sharedGames/GetSharedGamesSuccessAction'
import { SharedGamesReducer } from './types/SharedGamesReducer'

const postRequestAction: PostSharedGamesRequestAction['type'] =
  'POST_SHARED_GAMES_REQUEST_ACTION'

const patchRequestAction: PatchSharedGamesRequestAction['type'] =
  'PATCH_SHARED_GAMES_REQUEST_ACTION'

const deleteRequestAction: DeleteSharedGamesRequestAction['type'] =
  'DELETE_SHARED_GAMES_REQUEST_ACTION'

const deleteSuccessAction: DeleteSharedGamesSuccessAction['type'] =
  'DELETE_SHARED_GAMES_SUCCESS_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const getSharedGamesSuccess: GetSharedGamesSuccessAction['type'] =
  'GET_SHARED_GAMES_SUCCESS_ACTION'

export const sharedGamesReducer = createGenericReducer<
  SharedGameModel,
  SharedGamesReducer
>({
  getSuccessAction: getSharedGamesSuccess,
  postRequestAction,
  deleteRequestAction,
  deleteSuccessAction, // TODO: add delete success because another user may have done it.
  patchRequestAction,
  clearActions: [clearWorkspaceScopeAction],
  // debug: true,
})
