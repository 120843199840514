export const collectionDefault = {
  workspaces: 'teams',
  globalReminders: 'globalReminders',
  workspaceUsers: 'userExtensions',
  workspaceChanges: 'changes',
  sharedGames: 'sharedGames',
  games: 'games',
  cards: 'cards',
  gameStats: 'gameStats',
  aiSuggestions: 'aiSuggestions',
  jobs: 'jobs',
  invoices: 'invoices',
  tinyUrls: 'tinyUrls',
  simpleCards: 'simpleCards',
}
