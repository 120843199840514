import { envConfigBase } from 'kat/lib/envConfig/envConfigBase'
import { envConfigBaseDefault } from 'kat/lib/envConfig/envConfigBaseDefault'
import { EnvConfig } from './types/EnvConfig'

const env = process.env // Can't be const { env } = process, dont know why

let environment = envConfigBaseDefault.environment

if (env.REACT_APP_ENV === 'jsdom') {
  environment = 'jsdom'
}
if (env.REACT_APP_ENV === 'local') {
  environment = 'local'
}
if (env.REACT_APP_ENV === 'development') {
  environment = 'development'
}
if (env.REACT_APP_ENV === 'beta') {
  environment = 'beta'
}
if (env.REACT_APP_ENV === 'production') {
  environment = 'production'
}

const appName = 'PlaySnapGo'
const appVersion = '42' // use basic numbers because it will be converted to number
const buildTime = env.REACT_APP_BUILD_TIME || envConfigBaseDefault.buildTime
const publicUrl = env.REACT_APP_PUBLIC_URL || 'http://localhost:3005'
const feedbackWorkspaceId = env.FEEDBACK_WORKSPACE_ID || 'EzYnVWpYsQtrvfmVfmVw'

const uploadCapacityGigabytesPerUserPro = Number(
  env.REACT_APP_UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO ||
    envConfigBaseDefault.uploadCapacityGigabytesPerUserPro
)

const uploadCapacityGigabytesPerUserFree = Number(
  env.REACT_APP_UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE ||
    envConfigBaseDefault.uploadCapacityGigabytesPerUserFree
)

const maxMessagingTokens = Number(
  env.REACT_APP_MAX_MESSAGING_TOKENS || envConfigBaseDefault.maxMessagingTokens
)
const descriptionMaxLength = Number(
  env.REACT_APP_DESCRIPTION_MAX_LENGTH ||
    envConfigBaseDefault.descriptionMaxLength
)

const workingStaleSeconds = Number(
  env.REACT_APP_WORKING_STALE_SECONDS ||
    envConfigBaseDefault.workingStaleSeconds
)

const workspaceMaxUserCountFree = Number(
  env.REACT_APP_WORKSPACE_MAX_USER_COUNT_FREE ||
    envConfigBaseDefault.workspaceMaxUserCountFree
)

const workspaceMaxUserCountPro = Number(
  env.REACT_APP_WORKSPACE_MAX_USER_COUNT_PRO ||
    envConfigBaseDefault.workspaceMaxUserCountPro
)

// const subscriptionEndDeferalSeconds = Number(
//   env.REACT_APP_SUBSCRIPTION_END_DEFERAL_SECONDS ||
//     envConfigBaseDefault.subscriptionEndDeferalSeconds
// )

const iosAppStoreId = '1559109922'
const androidPackageName = 'com.playsnapgo'

const envConfigMutable: EnvConfig = {
  ...envConfigBaseDefault,
  environment,
  appName,
  appVersion,
  buildTime,
  publicUrl,
  termsUrl: `terms`,
  privacyUrl: `privacy`,
  googlePlayUrl: `https://play.google.com/store/apps/details?id=${androidPackageName}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`,
  appStoreUrl: `https://apps.apple.com/us/app/eggkat/id${iosAppStoreId}?itsct=apps_box_badge&amp;itscg=30200`,
  uploadCapacityGigabytesPerUserPro,
  uploadCapacityGigabytesPerUserFree,
  maxMessagingTokens,
  descriptionMaxLength,
  workingStaleSeconds,
  feedbackWorkspaceId,
  workspaceMaxUserCountFree,
  workspaceMaxUserCountPro,
  // subscriptionEndDeferalSeconds,
  enableWorkspaceLogo: false,
  androidPackageName,
  iosBundleId: 'org.lasirenafrida.eggkat',
  iosAppStoreId,
  enableReaderRole: false,
  uploadImageMaxWidthOrHeight: 1800,
  features: {
    autoEmojiTitle: ['local', 'development'],
  },
}

envConfigBase.extends(envConfigMutable)

export const initializeEnvConfig = () => {
  envConfigBase.extends(envConfigMutable)
}

export const envConfig = () => {
  return envConfigMutable
}

// export const isFeatureEnabled = (featureName: keyof EnvConfig['features']) => {
//   return envConfig().features[featureName]?.includes(envConfig().environment)
// }
