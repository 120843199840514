import { SetCurrentGameAction } from 'state/actions/SetCurrentGameAction'
import { CurrentGameReducer } from './types/CurrentGameReducer'

export const currentGameReducer = (
  state?: CurrentGameReducer | null,
  action?: SetCurrentGameAction
): CurrentGameReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CURRENT_GAME_ACTION': {
      return action.game
    }

    default:
      return state || null
  }
}
