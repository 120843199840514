import React, { FC, useEffect, memo } from 'react'
import { SingleWatcher } from 'kat/lib/com/watcher/SingleWatcher'
import { DocumentSnapshotClient } from 'kat/lib/sdks/firebase/types/DocumentSnapshotClient'
import { getFirestore } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { WatcherTypesBase } from 'kat/lib/types/WatcherTypesBase'
import { SetCurrentGameAction } from 'state/actions/SetCurrentGameAction'
import { GameModel } from 'functions/types/GameModel'
import { getGameRefClient } from 'functions/utilities/ref/game/getGameRefClient'

interface GameWatcherProps {
  gameId: string
}

const description = 'GameWatcher'

export const GameWatcher: FC<GameWatcherProps> = memo(({ gameId }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<SetCurrentGameAction>({
      type: 'SET_CURRENT_GAME_ACTION',
      description,
      game: null,
    })
  }, [dispatch])

  const workspaceId = useSelector((state: StateReducers) => {
    return state.currentWorkspaceId
  })

  if (!workspaceId) {
    return null
  }

  return (
    <SingleWatcher
      onNext={(docSnapshot: DocumentSnapshotClient<GameModel>) => {
        if (workspaceId) {
          const game = docSnapshot.data()
          if (game) {
            dispatch<SetCurrentGameAction>({
              type: 'SET_CURRENT_GAME_ACTION',
              description,
              game,
            })
          } else {
            dispatch<SetCurrentGameAction>({
              type: 'SET_CURRENT_GAME_ACTION',
              description,
              game: null,
            })
          }
        }
      }}
      getRef={() => {
        return getGameRefClient({
          firestore: getFirestore(),
          workspaceId,
          gameId,
        })
      }}
      watcherKey={description as WatcherTypesBase}
    />
  )
})
