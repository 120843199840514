import { flowsBase } from 'kat/lib/flows/flowsBase'
import { all, spawn, call } from 'redux-saga/effects'

const base = flowsBase()

export const flows = function* root() {
  const allFlows = [
    ...base,
  ]

  yield all(
    allFlows.map((flow) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(flow)
            break
          } catch (e) {
            console.log(e)
          }
        }
      })
    )
  )
}
