const env = process.env

export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN

export const STRIPE_ID =
  env.REACT_APP_STRIPE_ID ||
  'pk_test_51MtI11GVmtHIltL7OMHEwKLJ6IKVuvW8I13NHWnUwJEEe7nhLkkXLRWXM6OnkOMBlqBWkuhzDJfHIDFs8VRcqmqm00KAabPVAR'

export const FIREBASE_API_KEY =
  env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBe4gjuCLoD5Tbw9NT-AV39oE6flE8j70E'
export const FIREBASE_PROJECT_ID =
  env.REACT_APP_FIREBASE_PROJECT_ID || 'photobingo-local'
export const FIREBASE_AUTH_DOMAIN =
  env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  `${FIREBASE_PROJECT_ID}.firebaseapp.com` /* should be the public domain if available */

export const FIREBASE_DATABASE_URL =
  env.REACT_APP_FIREBASE_DATABASE_URL ||
  `https://${FIREBASE_PROJECT_ID}.firebaseio.com`
export const FIREBASE_STORAGE_BUCKET =
  env.REACT_APP_FIREBASE_STORAGE_BUCKET || `${FIREBASE_PROJECT_ID}.appspot.com`
export const FIREBASE_MESSAGING_SENDER_ID =
  env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '396823894648'
export const FIREBASE_APP_ID =
  env.REACT_APP_FIREBASE_APP_ID || '1:396823894648:web:bd206c9a7cd733ba4e6b8d'

export const FIREBASE_FUNCTIONS_BASE_URL =
  env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL ||
  `https://us-central1-${FIREBASE_PROJECT_ID}.cloudfunctions.net`

export const PUBLIC_VAPID_KEY =
  env.REACT_APP_PUBLIC_VAPID_KEY ||
  'BJSqCqREFLv9KCZ7q77NZ0fSXWQke4gWt_Iw8_B5B2pGzNzhKqD3wmyfLMCf9CitgEYv-TTSaT-qzzse1Fa_oOY'

export const FIREBASE_MEASUREMENT_ID =
  env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-E9HWJE3026'
