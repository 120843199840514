import { css } from 'emotion'
import React, { FC } from 'react'
import { descriptionHelper } from 'kat/lib/utilities/descriptionHelper'
import { iconStyles } from './iconStyles'

interface SnapGoIconProps {
  styles?: string
}

export const SnapGoIcon: FC<SnapGoIconProps> = ({ styles = '' }) => {
  return (
    <svg
      className={
        descriptionHelper('SnapGoIcon ') +
        ` ${iconStyles()} ${css`
          ${styles}
        `}`
      }
      fill="none"
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 512 512"
    >
      <path
        d="M256.098 512.193C397.312 512.193 511.788 397.718 511.788 256.504C511.788 115.289 397.312 0.81311 256.098 0.81311C114.885 0.81311 0.407593 115.289 0.407593 256.504C0.407593 397.718 114.885 512.193 256.098 512.193Z"
        fill="#E91D62"
      />
      <path
        d="M256.336 321.81C292.56 321.81 321.926 292.445 321.926 256.221C321.926 219.996 292.56 190.631 256.336 190.631C220.112 190.631 190.746 219.996 190.746 256.221C190.746 292.445 220.112 321.81 256.336 321.81Z"
        fill="#1E1E1E"
      />
      <path
        d="M253.654 511.674C253.654 511.674 247.425 310.025 55 330.454L253.654 511.674Z"
        fill="#1E1E1E"
      />
    </svg>
  )
}
