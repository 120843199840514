export const appRoutes = {
  receiptsRoute: {
    pathname: '/receipts',
    isDashboardLayout: true,
  },
  onboardRoute: {
    pathname: '/onboard',
    isMinimalLayout: true,
  },
  playGameRoute: {
    pathname: '/play',
    isPreLogin: true,
  },
  weddingMainRoute: {
    pathname: '/wedding',
    isPreLogin: true,
  },
  corporateMainRoute: {
    pathname: '/corporate',
    isPreLogin: true,
  },
  highlightsRoute: {
    pathname: '/highlights',
    isPreLogin: true,
  },
  adminWorkspaceRoute: {
    pathname: '/admin-workspace',
  },
  tinyRoute: {
    pathname: '/tiny', // also used in index.html
    isPreLogin: true,
  },
  printQrCodeRoute: {
    pathname: '/print-qr-code',
    useLayout: 'print' as const,
  },
  hostGuestRoute: {
    pathname: '/host-guest',
    isPreLogin: true,
  },
  accountSettingsRoute: {
    pathname: '/account-settings',
    isOneLayout: true,
  },
  workspaceHomeRoute: {
    pathname: '/workspace/home',
    isDashboardLayout: true,
  },
  gameRoute: {
    pathname: '/game',
    isDashboardLayout: true,
  },
  cardsRoute: {
    pathname: '/cards',
    isDashboardLayout: true,
  },
  cardRoute: {
    pathname: '/card',
    isDashboardLayout: true,
  },
  postLoginHomeRoute: {
    pathname: '/dashboard',
    isOneLayout: true,
  },
  loginRoute: {
    pathname: '/login',
    isPreLogin: true,
    isOneLayout: true,
  },
  rootRoute: {
    pathname: '/',
    isPreLogin: true,
  },
  termsRoute: {
    pathname: '/terms',
    isPreLogin: true,
    isOneLayout: true,
  },
  privacyRoute: {
    pathname: '/privacy',
    isPreLogin: true,
    isOneLayout: true,
  },
  createUserRoute: {
    pathname: '/create-account',
    isPreLogin: true,
    isOneLayout: true,
  },
  confirmEmailRoute: {
    pathname: '/confirm-email',
    isPreLogin: true,
    isMinimalLayout: true,
  },
  serverErrorTestRoute: {
    pathname: '/server-error-test',
    isPreLogin: true,
    isOneLayout: true,
  },
  jsErrorTestRoute: {
    pathname: '/js-error-test',
    isPreLogin: true,
    isOneLayout: true,
  },
  networkErrorTestRoute: {
    pathname: '/network-error-test',
    isPreLogin: true,
    isOneLayout: true,
  },
  resetPasswordRoute: {
    pathname: '/reset-password',
    isPreLogin: true,
    isOneLayout: true,
  },
  updateAvatarRoute: {
    pathname: '/update-avatar',
    isOneLayout: true,
  },
  workspaceSettingsRoute: {
    pathname: '/workspace-settings',
    isOneLayout: true,
  },
  proCanceledRoute: {
    pathname: '/pro-canceled',
    isOneLayout: true,
  },
  updateWorkspaceLogoRoute: {
    pathname: '/update-workspace-logo',
    isOneLayout: true,
  },
  updateWorkspaceNameRoute: {
    pathname: '/update-workspace-name',
    isOneLayout: true,
  },
  paymentFailedRoute: {
    pathname: '/payment-failed',
    isOneLayout: true,
  },
  updateDisplayNameRoute: {
    pathname: '/update-display-name',
    isOneLayout: true,
  },
  updatePasswordRoute: {
    pathname: '/update-password',
    isOneLayout: true,
  },
  notificationSettingsRoute: {
    pathname: '/notification-settings',
    isOneLayout: true,
  },
  darkModeRoute: {
    pathname: '/dark-mode',
    isOneLayout: true,
  },
  upgradeWorkspaceRoute: {
    pathname: '/upgrade',
    isOneLayout: true,
  },
  inviteDetailRoute: {
    pathname: '/invite-detail',
    isOneLayout: true,
  },
  memberDetailRoute: {
    pathname: '/member-detail',
    isOneLayout: true,
  },
  acceptInviteRoute: {
    pathname: '/accept-invitation',
    isOneLayout: true,
  },
  selectWorkspaceRoute: {
    pathname: '/select-workspace',
    isMinimalLayout: true,
  },
  emailLoginRoute: {
    pathname: '/email-login',
    isOneLayout: true,
    isPreLogin: true,
  },
  deleteUserAccountRoute: {
    pathname: '/delete-user-account',
    isOneLayout: true,
  },
  deleteWorkspaceRoute: {
    pathname: '/delete-workspace',
    isOneLayout: true,
  },
  leaveWorkspaceRoute: {
    pathname: '/leave-workspace',
    isOneLayout: true,
  },
  foldersRoute: {
    pathname: '/folders',
    isDashboardLayout: true,
  },
  membersRoute: {
    pathname: '/membersMain',
    isDashboardLayout: true,
    isOneLayout: false,
  },
  welcomeStepRoute: {
    pathname: '/welcome',
    isOneLayout: true,
  },
  activityNotificationRoute: {
    pathname: '/activity-notification',
    isOneLayout: true,
  },
  adminRoute: {
    pathname: '/admin-debug',
    isPreLogin: true,
  },
  reportRoute: {
    pathname: '/report',
    isOneLayout: true,
  },
  welcomeToProRoute: {
    pathname: '/welcomeToPro',
    isOneLayout: true,
  },
  workspaceFilesRoute: {
    pathname: '/workspaceFiles',
    isOneLayout: true,
  },
  workspaceSubscriptionRoute: {
    pathname: '/workspaceSubscription',
    isOneLayout: true,
  },
  archiveRoute: {
    pathname: '/archive',
    isDashboardLayout: true,
  },
}
