import { getGamesRefClient } from './getGamesRefClient'
import { GameModel } from '../../../types/GameModel'
import { DocumentReferenceClient } from 'kat/lib/sdks/firebase/types/DocumentReferenceClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { doc } from 'firebase/firestore'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
  gameId: string
}

export const getGameRefClient = ({
  firestore,
  workspaceId,
  gameId,
}: Props): DocumentReferenceClient<GameModel> => {
  const gamesRef = getGamesRefClient({ firestore, workspaceId })
  const ref = doc(gamesRef, gameId)
  return ref
}
