import { SetCurrentCardAction } from 'state/actions/SetCurrentCardAction'
import { CurrentCardReducer } from './types/CurrentCardReducer'

export const currentCardReducer = (
  state?: CurrentCardReducer | null,
  action?: SetCurrentCardAction
): CurrentCardReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CURRENT_CARD_ACTION': {
      return action.card
    }

    default:
      return state || null
  }
}
