import { SCROLL_CONTAINER_ID } from 'kat/lib/constants/domConstants'
import { FC, useEffect } from 'react'
import { StateReducers } from 'state/reducers/types/StateReducers'
import { useSelector } from 'react-redux'

const scrollTop = () => {
  const element =
    document.getElementById(SCROLL_CONTAINER_ID) || document.documentElement

  element.scrollTo({ top: 0, behavior: 'smooth' })
}

export const ScrollPositionWatcher: FC = () => {
  const isRoutes = useSelector((state: StateReducers) => {
    return state.currentLocation?.isRoutes
  })

  const cardId = useSelector((state: StateReducers) => {
    return state.currentLocation?.query?.cardId
  })

  const cardRoute = !!isRoutes?.cardRoute

  useEffect(() => {
    if (cardRoute && cardId) {
      scrollTop()
    }
  }, [cardRoute, cardId])

  return null
}
