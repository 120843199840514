import {
  analyticsEventBase,
  AnalyticsEventBase,
} from 'kat/lib/sdks/firebase/analyticsEventBase'

type AnalyticsEvent = AnalyticsEventBase<
  | 'Create Card'
  | 'Too Many Tasks'
  | 'Status Suggestion'
  | 'New task item'
  | 'Advance mode on'
  | 'Advance mode off'
  | 'Begin Onboarding'
  | 'Suggest Ai - note item'
  | 'Suggest Ai - Use note'
  | 'Suggest Ai - Suggest note'
  | 'Create your first group'
  | 'Select template'
  | 'Get started: Top'
  | 'Get started: Bottom'
  | 'Get started: Center'
  | 'Go back'
>

interface Props {
  event: AnalyticsEvent
  params?: {
    currentUserId?: string | null | undefined // TODO: wait to see if we need this, because we're using CurrentUserIdWatcher now
    templateType?: string
  }
}

export const logAnalyticsEvent = ({ event, params }: Props) => {
  const eventParamsMutable: {
    partialUid?: string
    templateType?: string
  } = {}
  // if (params?.currentUserId) {
  //   eventParamsMutable.partialUid = params?.currentUserId?.slice(0, 8)
  // }
  if (params?.templateType) {
    eventParamsMutable.templateType = params?.templateType
  }

  analyticsEventBase({ event, eventParams: eventParamsMutable })
}
