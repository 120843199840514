import React, { FC, useEffect } from 'react'
import { css } from 'emotion'
import { descriptionHelper } from 'kat/lib/utilities/descriptionHelper'
import { appRoutes } from 'functions/routes/appRoutes'
import { SnapGoIcon } from 'com/icon/SnapGoIcon'

interface RootLoaderMainProps {
  styles?: string
}

const description = 'RootLoaderMain'

export const ROOT_LOADER_MAIN_ID = 'RootLoaderMain'

// The purpose of RootLoaderMain is to use prerender to show something fast to the client
export const RootLoaderMain: FC<RootLoaderMainProps> = ({ styles = '' }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.text = `
        document.getElementById('${ROOT_LOADER_MAIN_ID}').style.display = (location.pathname === '${appRoutes.playGameRoute.pathname}' || location.pathname === '${appRoutes.highlightsRoute.pathname}' || location.pathname === '${appRoutes.tinyRoute.pathname}')
      ? 'block'
      : 'none'`

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      id={ROOT_LOADER_MAIN_ID}
      className={
        descriptionHelper(description + ' ') +
        css`
          z-index: 1000;
          position: fixed;
          ${styles};
        `
      }
    >
      <div
        className={css`
          width: 100vw;
          height: 100dvh;
          position: fixed;
          top: 0px;
          left: 0px;
          background-color: rgb(255, 255, 255);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          flex-direction: column;
        `}
      >
        <div>
          <div
            className={css`
              position: relative;
              display: flex;
              padding-top: 30px;
              font-size: 40px;
              font-weight: 900;
              font-family: Oswald, Helvetica, Arial, sans-serif;
              margin-bottom: 100px;

              span {
                position: relative;
                color: #e91d63;
                min-width: 15px;
                animation: 1s ease 0.1s infinite normal none running bounce;
              }

              @keyframes bounce {
                0% {
                  -webkit-transform: scale(1, 1) translateY(0);
                  -ms-transform: scale(1, 1) translateY(0);
                  transform: scale(1, 1) translateY(0);
                }
                10% {
                  -webkit-transform: scale(1.1, 0.9) translateY(0);
                  -ms-transform: scale(1.1, 0.9) translateY(0);
                  transform: scale(1.1, 0.9) translateY(0);
                }
                30% {
                  -webkit-transform: scale(0.9, 1.1) translateY(-35px);
                  -ms-transform: scale(0.9, 1.1) translateY(-35px);
                  transform: scale(0.9, 1.1) translateY(-35px);
                }
                50% {
                  -webkit-transform: scale(1.05, 0.95) translateY(0);
                  -ms-transform: scale(1.05, 0.95) translateY(0);
                  transform: scale(1.05, 0.95) translateY(0);
                }
                58% {
                  -webkit-transform: scale(1, 1) translateY(-7px);
                  -ms-transform: scale(1, 1) translateY(-7px);
                  transform: scale(1, 1) translateY(-7px);
                }
                65% {
                  -webkit-transform: scale(1, 1) translateY(0);
                  -ms-transform: scale(1, 1) translateY(0);
                  transform: scale(1, 1) translateY(0);
                }
                100% {
                  -webkit-transform: scale(1, 1) translateY(0);
                  -ms-transform: scale(1, 1) translateY(0);
                  transform: scale(1, 1) translateY(0);
                }
              }
            `}
          >
            <span>
              <SnapGoIcon />
            </span>
            <span> </span>

            <span>S</span>
            <span>n</span>
            <span>a</span>
            <span>p</span>
            <span>G</span>
            <span>o</span>
          </div>
        </div>
      </div>
    </div>
  )
}
