import { GameStatModel } from './../../../types/GameStatModel'
import { getGameStatsRefClient } from './getGameStatsRefClient'
import { DocumentReferenceClient } from 'kat/lib/sdks/firebase/types/DocumentReferenceClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { doc } from 'firebase/firestore'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
  gameId: string
}

export const getGameStatRefClient = ({
  firestore,
  workspaceId,
  gameId,
}: Props): DocumentReferenceClient<GameStatModel> => {
  const gamesRef = getGameStatsRefClient({ firestore, workspaceId })
  const ref = doc(gamesRef, gameId)
  return ref
}
