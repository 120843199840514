import { ClearCardsAction } from './../actions/ClearCardsAction'
import { createGenericReducer } from 'kat/lib/state/reducers/createGenericReducer'
import { ClearWorkspaceScopeAction } from 'kat/lib/actionCreators/types/ClearWorkspaceScopeAction'
import { CardModel } from '../../functions/types/CardModel'
import { PostCardsRequestAction } from '../actions/cards/PostCardsRequestAction'
import { PatchCardsRequestAction } from '../actions/cards/PatchCardsRequestAction'
import { DeleteCardsRequestAction } from '../actions/cards/DeleteCardsRequestAction'
import { DeleteCardsSuccessAction } from '../actions/cards/DeleteCardsSuccessAction'
import { GetCardsSuccessAction } from '../actions/cards/GetCardsSuccessAction'
import { CardsReducer } from './types/CardsReducer'
import { ClearGameScopeAction } from 'state/actions/ClearGameScopeAction'

const postRequestAction: PostCardsRequestAction['type'] =
  'POST_CARDS_REQUEST_ACTION'

const patchRequestAction: PatchCardsRequestAction['type'] =
  'PATCH_CARDS_REQUEST_ACTION'

const deleteRequestAction: DeleteCardsRequestAction['type'] =
  'DELETE_CARDS_REQUEST_ACTION'

const deleteSuccessAction: DeleteCardsSuccessAction['type'] =
  'DELETE_CARDS_SUCCESS_ACTION'

const clearWorkspaceScopeAction: ClearWorkspaceScopeAction['type'] =
  'CLEAR_WORKSPACE_SCOPE'

const clearGameScopeAction: ClearGameScopeAction['type'] =
  'CLEAR_GAME_SCOPE_ACTION'

const clearCardsAction: ClearCardsAction['type'] = 'CLEAR_CARDS_ACTION'

const getCardsSuccess: GetCardsSuccessAction['type'] =
  'GET_CARDS_SUCCESS_ACTION'

export const cardsReducer = createGenericReducer<CardModel, CardsReducer>({
  getSuccessAction: getCardsSuccess,
  postRequestAction,
  deleteRequestAction,
  deleteSuccessAction, // TODO: add delete success because another user may have done it.
  patchRequestAction,
  clearActions: [
    clearWorkspaceScopeAction,
    clearGameScopeAction,
    clearCardsAction,
  ],
  // debug: true,
})
