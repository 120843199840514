import { getSharedGamesRefClient } from './getSharedGamesRefClient'
import { DocumentReferenceClient } from 'kat/lib/sdks/firebase/types/DocumentReferenceClient'
import { FirestoreClient } from 'kat/lib/sdks/firebase/types/FirestoreClient'
import { SharedGameModel } from '../../../types/SharedGameModel'
import { doc } from 'firebase/firestore'

interface Props {
  firestore: FirestoreClient
  workspaceId: string
  gameId: string
}

export const getSharedGameRefClient = ({
  firestore,
  workspaceId,
  gameId,
}: Props): DocumentReferenceClient<SharedGameModel> => {
  return doc(
    getSharedGamesRefClient({
      firestore,
      workspaceId,
    }),
    gameId
  )
}
