import { SetCurrentGameStatAction } from './../actions/SetCurrentGameStatAction'
import { CurrentGameStatReducer } from './types/CurrentGameStatReducer'

export const currentGameStatReducer = (
  state?: CurrentGameStatReducer | null,
  action?: SetCurrentGameStatAction
): CurrentGameStatReducer | null => {
  if (!action) {
    return state || null
  }

  switch (action.type) {
    case 'SET_CURRENT_GAME_STAT_ACTION': {
      const gameStat = action.gameStat

      return gameStat
    }

    default:
      return state || null
  }
}
